import React, { useMemo } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { HiArrowNarrowLeft } from "react-icons/hi"
import { animated, useTrail, config } from "react-spring"
import format from "date-fns/format"
import PageAnimator from "components/PageAnimator"
import TransitionLink from "components/TransitionLink"
import SEO from "components/Seo"
import ModeToggleButton from "components/ModeToggleButton"
import TriangleImg from "images/triangle.svg"
import { th, sizes } from "styles"

const Container = styled(PageAnimator)`
  padding: 20px 40px 80px 40px;
  margin: 0 auto;

  @media ${sizes.mobileLarge} {
    max-width: 800px;
  }

  @media ${sizes.tabletPortrait} {
    padding-bottom: 80px;
  }

  @media ${sizes.tabletLandscape} {
    padding-top: 60px;
  }

  @media ${sizes.smallDesktop} {
    max-width: 1000px;
    padding-bottom: 140px;
  }
`

const PlumiaLink = styled.a`
  position: absolute;
  left: 40px;
  display: none;

  > svg {
    vertical-align: middle;
  }

  @media ${sizes.tabletLandscape} {
    top: 70px;
    display: block;
  }

  @media ${sizes.smallDesktop} {
    top: 90px;
  }

  @media ${sizes.desktop} {
    left: 100px;
  }
`

const PreviewImage = styled(Img)`
  border-radius: 10px;
  transition: transform 200ms;
`

const ImageContainer = styled.div`
  position: relative;
  width: 85%;
  max-width: 340px;
  margin-bottom: 16px;

  @media ${sizes.tabletLandscape} {
    margin-bottom: 0;
    margin-right: 60px;
  }

  @media ${sizes.desktop} {
    max-width: 400px;
  }

  ::after {
    content: " ";
    position: absolute;
    bottom: -12px;
    right: -12px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: ${th("textColor")};
    z-index: -1;
  }
`

const TextContainer = styled.div`
  position: relative;
  z-index: 2;

  > h2 {
    @media ${sizes.tabletLandscape} {
      margin-top: 0;
    }
  }
  @media ${sizes.tabletLandscape} {
    align-self: flex-end;
  }
`

const HoverEffect = styled.div`
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: opacity 400ms;

  @media ${sizes.smallDesktop} {
    display: block;
  }
`

const StyledBlogPost = styled(animated.div)`
  :not(:last-child) {
    margin-bottom: 40px;

    @media ${sizes.tabletLandscape} {
      margin-bottom: 80px;
    }
  }

  > a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;

    @media ${sizes.tabletLandscape} {
      flex-direction: row;
    }

    :hover {
      opacity: 1;
      color: ${th("textColor")};

      > ${ImageContainer} > ${PreviewImage} {
        transform: translate3d(12px, 12px, 0);
      }

      > ${TextContainer} {
        > ${HoverEffect} {
          opacity: 1;
        }
        > h2 {
          color: ${th("color.blue")};
        }
      }
    }
  }
`

const Rectangle = styled.div`
  position: absolute;
  top: -55px;
  left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transform: rotate(-25deg);
  background: linear-gradient(${th("color.yellow")}, ${th("color.pink")});
`

const Circle = styled.div`
  position: absolute;
  top: -45px;
  right: 50px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: radial-gradient(${th("color.white")}, ${th("color.nude")});
`

const Triangle = styled.div`
  position: absolute;
  bottom: 20px;
  left: -120px;
  width: 90px;
  height: 90px;
  transform: rotate(-25deg);

  > img {
    width: 100%;
    height: auto;
  }
`

const Authors = styled.div`
  margin-bottom: 12px;
`

const PublishedOn = styled.div`
  @media ${sizes.tabletLandscape} {
    margin-bottom: 12px;
  }
`

const IndexPage = ({ data }) => {
  const { allContentfulBlogPost } = data

  const sortedBlogPosts = useMemo(
    () =>
      allContentfulBlogPost.edges.sort(
        (a, b) =>
          new Date(b.node.publishedOn).getTime() -
          new Date(a.node.publishedOn).getTime()
      ),
    [allContentfulBlogPost]
  )

  const trail = useTrail(sortedBlogPosts.length, {
    config: config.stiff,
    delay: 100,
    opacity: 1,
    transform: "translate3d(0px, 0px, 0px)",
    from: { opacity: 0, transform: "translate3d(0px, 20px, 0px)" },
  })

  return (
    <>
      <PlumiaLink href="https://plumia.country">
        <HiArrowNarrowLeft /> Plumia website
      </PlumiaLink>
      <Container>
        <SEO title="Plumia Papers" />
        {trail.map((props, i) => {
          const blogPost = sortedBlogPosts[i].node
          return (
            <StyledBlogPost key={i} style={props}>
              <TransitionLink to={`/${blogPost.slug}`}>
                <ImageContainer>
                  <PreviewImage
                    fluid={{
                      ...blogPost.featuredImage.fluid,
                      aspectRatio: 1 / 1,
                    }}
                    alt={blogPost.featuredImage.title}
                  />
                </ImageContainer>
                <TextContainer>
                  <HoverEffect>
                    <Rectangle />
                    <Circle />
                    <Triangle>
                      <img src={TriangleImg} alt="triangle" />
                    </Triangle>
                  </HoverEffect>
                  <h2>{blogPost.title}</h2>
                  <Authors>{`by ${blogPost.authors}`}</Authors>
                  <PublishedOn>
                    {format(new Date(blogPost.publishedOn), "MMM d, yyyy")}
                  </PublishedOn>
                </TextContainer>
              </TransitionLink>
            </StyledBlogPost>
          )
        })}
      </Container>
      <ModeToggleButton variant="small" />
    </>
  )
}

export const pageQuery = graphql`
  query IndexPageQuery {
    allContentfulBlogPost {
      edges {
        node {
          title
          slug
          featuredImage {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          publishedOn
          authors
        }
      }
    }
  }
`

export default IndexPage
